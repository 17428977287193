<template>
  <div class="row">
    <div class="col-lg-9 col-md-9 col-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-12 mb-3">
              <h4>Right to Work in Australia Check</h4>
              <div class="underline"></div>
            </div>
          </div>

          <div class="row">
            <div
              class="alert alert-danger mb-5 col-12"
              v-if="arrErr.length > 0"
            >
              <p
                class="fs-800 mb-0"
                v-for="(item, index) in arrErr"
                :key="index"
              >
                {{ item }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <p class="text-info">Personal details</p>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-4 col-12">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  id="firstName"
                  autocomplete="off_firstName"
                  placeholder=" "
                  v-model="step1.first_name"
                />
                <label class="floatingLabel" for="firstName">Given name</label>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  id="middleName"
                  autocomplete="off_middleName"
                  placeholder=" "
                  v-model="step1.middle_name"
                />
                <label class="floatingLabel" for="firstName">Middle name</label>
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12">
              <div class="form-group">
                <input
                  class="form-control"
                  type="text"
                  id="lastName"
                  autocomplete="off_lastName"
                  placeholder=" "
                  v-model="step1.last_name"
                />
                <label class="floatingLabel" for="lastName">Family name</label>
                <div class="invalid-feedback col-12" v-if="errors.last_name">
                  {{ errors.last_name[0] }}
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-4">
            <div class="col-xl-4 col-lg-4 col-md-4 col-12">
              <b-form-checkbox
                v-if="step1.first_name == '' || step1.first_name == null"
                id="checkbox-1"
                name="checkbox-1"
                value="No"
                unchecked-value="Yes"
                v-model="step1.have_a_given_name"
              >
                Does not have a given name
              </b-form-checkbox>
              <div
                class="invalid-feedback col-12"
                v-if="errors.have_a_given_name"
              >
                {{ errors.have_a_given_name[0] }}
              </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-12">
              <b-form-checkbox
                v-if="step1.middle_name == '' || step1.middle_name == null"
                id="checkbox-2"
                name="checkbox-2"
                value="No"
                unchecked-value="Yes"
                v-model="step1.have_a_middle_name"
              >
                Does not have a middle name
              </b-form-checkbox>
              <div
                class="invalid-feedback col-12"
                v-if="errors.have_a_middle_name"
              >
                {{ errors.have_a_middle_name[0] }}
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xl-2 col-lg-2 col-md-4 col-12">
              <div class="form-group">
                <input
                  class="form-control"
                  type="date"
                  id="dateOfBirth"
                  autocomplete="off_dateOfBirth"
                  placeholder=" "
                  v-model="step1.date_of_birth"
                />
                <label class="floatingLabel" for="dateOfBirth"
                  >Date of birth</label
                >
                <div
                  class="invalid-feedback col-12"
                  v-if="errors.date_of_birth"
                >
                  {{ errors.date_of_birth[0] }}
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-xl-4 col-lg-4 col-md-4 col-12">
              <p class="text-info">Document type</p>

              <div class="form-group">
                <select class="form-control" v-model="step2.document_type">
                  <option
                    v-for="(item, index) in optionsRightToWork"
                    :value="item.value"
                    :key="index"
                  >
                    {{ item.text }}
                  </option>
                </select>
                <label
                  class="floatingLabel vueBootSelect"
                  for="state_of_Licence"
                  >Select document type</label
                >
                <div
                  class="invalid-feedback col-12"
                  v-if="errors.document_type"
                >
                  {{ errors.document_type[0] }}
                </div>
              </div>
            </div>
          </div>

          <!-- if passport -->
          <div class="row mt-5" v-if="step2.document_type == 'passport'">
            <div class="col-12">
              <p class="text-info">Passport details</p>
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-4 col-12">
                  <div class="form-group">
                    <input
                      class="form-control"
                      type="text"
                      id="firstName"
                      autocomplete="off_firstName"
                      placeholder=" "
                      v-model="step2.passport_number"
                    />
                    <label class="floatingLabel" for="firstName"
                      >Passport Number</label
                    >
                    <div
                      class="invalid-feedback col-12"
                      v-if="errors.passport_number"
                    >
                      {{ errors.passport_number[0] }}
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-12">
                  <div class="form-group">
                    <select class="form-control" v-model="step2.country_code">
                      <option
                        v-for="(country, i) in getSettingAll.country"
                        :value="country.alpha_3"
                        :key="i"
                      >
                        {{ country.name }}
                      </option>
                    </select>
                    <label
                      class="floatingLabel vueBootSelect"
                      for="state_of_Licence"
                      >Country of Passport</label
                    >
                    <div
                      class="invalid-feedback col-12"
                      v-if="errors.country_code"
                    >
                      {{ errors.country_code[0] }}
                    </div>
                  </div>
                </div>

                <div class="col-xl-4 col-lg-4 col-md-4 col-12">
                  <div class="sits-file-upload_2">
                    <div class="upload-view">
                      <div class="mr500">
                        <input
                          type="file"
                          id="actual-btn"
                          ref="file"
                          @change="uploadFile"
                          hidden
                        />
                        <!-- our custom upload button -->
                        <label class="upload-label" for="actual-btn"
                          ><i class="fas fa-cloud-upload-alt text-info"></i>
                          Upload document/s</label
                        >
                      </div>

                      <wdImageAvatarPreview
                        :title="'Passport details'"
                        :coverImgUrl="step2.doc_urls"
                        :fontAwClass="'fas fa-file-alt'"
                        :fontAwSize="'fs-2300'"
                      ></wdImageAvatarPreview>
                    </div>
                    <!-- name of file chosen -->
                    <span v-if="document.name" id="file-chosen">{{
                      document.name
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- if birth certificate -->
          <div
            class="row mt-5"
            v-if="step2.document_type == 'birth_certificate'"
          >
            <div class="col-12">
              <p class="text-info">Birth certificate</p>
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col-xl-4 col-lg-4 col-md-4 col-12">
                  <div class="form-group">
                    <input
                      class="form-control"
                      type="text"
                      id="firstName"
                      autocomplete="off_firstName"
                      placeholder=" "
                      v-model="step2.passport_number"
                    />
                    <label class="floatingLabel" for="firstName">
                      Certificate number</label
                    >
                    <div
                      class="invalid-feedback col-12"
                      v-if="errors.passport_number"
                    >
                      {{ errors.passport_number[0] }}
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-12">
                  <div class="form-group">
                    <select class="form-control" v-model="step2.country_code">
                      <option
                        v-for="(country, i) in getSettingAll.country"
                        :value="country.alpha_3"
                        :key="i"
                      >
                        {{ country.name }}
                      </option>
                    </select>
                    <label
                      class="floatingLabel vueBootSelect"
                      for="state_of_Licence"
                      >Country of birth</label
                    >
                    <div
                      class="invalid-feedback col-12"
                      v-if="errors.country_code"
                    >
                      {{ errors.country_code[0] }}
                    </div>
                  </div>
                </div>

                <div class="col-xl-4 col-lg-4 col-md-4 col-12">
                  <div class="sits-file-upload_2">
                    <div class="upload-view">
                      <div class="mr500">
                        <input
                          type="file"
                          id="actual-btn"
                          ref="file"
                          @change="uploadFile"
                          hidden
                        />
                        <!-- our custom upload button -->
                        <label class="upload-label" for="actual-btn"
                          ><i class="fas fa-cloud-upload-alt text-info"></i>
                          Upload document/s</label
                        >
                      </div>

                      <wdImageAvatarPreview
                        :title="'Passport details'"
                        :coverImgUrl="step2.doc_urls"
                        :fontAwClass="'fas fa-file-alt'"
                        :fontAwSize="'fs-2300'"
                      ></wdImageAvatarPreview>
                    </div>
                    <!-- name of file chosen -->
                    <span v-if="document.name" id="file-chosen">{{
                      document.name
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12 text-right">
              <router-link
                v-if="loginUserObj.role_id == 9"
                class="btn btn-secondary btn-sm mr-2"
                :to="{ name: 'dashboardCC' }"
                >Cancel</router-link
              >
              <button
                v-if="can_cc_update()"
                class="btn btn-info btn-sm"
                @click="fnUpdate()"
              >
                <b-spinner small v-if="loading" class="mr-2"></b-spinner>
                Submit
              </button>

              <button
                v-if="can_cvs_update()"
                class="btn btn-info btn-sm"
                @click="fnSave()"
              >
                <b-spinner small v-if="loading" class="mr-2"></b-spinner>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-3 col-12">
      <div class="card h-overflow">
        <div class="card-body">
          <appStep2Ins></appStep2Ins>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import * as chkRightWorkAustraliaService from "../../services/chkRightWorkAustralia.service";

import * as messageService from "../../services/message.service";
import { optionsRightToWork } from "../../services/data.service";
import appStep2Ins from "../policeCheck/step2Ins.vue";
import * as authService from "../../services/auth.service";
import * as encodeDecodeService from "../../services/encodeDecode.service";
import wdImageAvatarPreview from "../../components/wd/WDImageAvatarPreview.vue";

export default {
  name: "RightToWorkInAustraliaCom",
  props: ["mode", "projectId", "productId", "userId"],
  components: {
    appStep2Ins,
    wdImageAvatarPreview,
  },
  data() {
    return {
      loginUserObj: {},
      arrErr: [],
      errors: {},
      fileRemoveDisabled: true,
      document: "",
      stateOfLicence: "",
      loading: false,
      step1: {
        gender_code: "M",
        first_name: "",
        middle_name: "",
        last_name: "",
        date_of_birth: "",
        country_of_birth_code: "Australia",
        email: "",
        mobile_no: "",
        have_a_given_name: "Yes",
        have_a_middle_name: "Yes",
        have_a_family_name: "Yes",
        unit_no: "",
        street_no: "",
        street: "",
        suburb: "",
        state_code: "",
        post_code: "",
        country_code: "",
      },
      step2: {
        document_type: "",
        passport_number: "",
        country_code: "",
        doc_urls: "",
        status: 1,
      },
      optionsRightToWork: optionsRightToWork,
    };
  },
  computed: {
    ...mapGetters(["getSettingAll", "getRightWorkAustralia", "getUserFromId"]),
  },
  methods: {
    ...mapActions(["fetchRightWorkAustralia", "fetchUserFromId"]),

    uploadFile() {
      this.document = this.$refs.file.files[0];
    },
    fnUpdate: async function () {
      this.loading = true;
      this.errors = {};
      this.fnValidation();

      if (this.arrErr.length > 0) {
        return;
      }

      const formData = new FormData();
      formData.append("document_type", this.step2.document_type);
      formData.append("file", this.document);
      formData.append("project_id", this.projectId);
      formData.append("passport_number", this.step2.passport_number);
      formData.append("country_code", this.step2.country_code);
      formData.append("status", 2);

      try {
        const res = await chkRightWorkAustraliaService.updateUserInfo(
          this.userId,
          this.step1
        );

        const response = await chkRightWorkAustraliaService.addOrUpdate(
          this.projectId,
          formData
        );

        if (response.data.status_code == 200) {
          this.fetchRightWorkAustralia(this.projectId);
          this.fetchUserFromId(this.userId);

          if (this.loginUserObj.role_id == 9) {
            messageService.fnToastSuccess("Submit successfully!");
            this.$router.push({
              name: "checkSuccessfulSubmissionMsgCC",
              params: {
                productId: encodeDecodeService.cyEncrypt(this.productId),
              },
            });
          } else {
            messageService.fnToastSuccess("Saved successfully!");
          }
        } else {
          return;
        }
        this.loading = false;
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;

          case 500:
            messageService.fnSweetAlertErrorToast(
              "Right to work in Australia",
              error.response.data.message
            );
            break;

          default:
            messageService.fnSweetAlertErrorToast(
              "Right to work in Australia",
              "Something wrong"
            );
            break;
        }
        this.loading = false;

        console.log("Something wrong - fnUpdate", error);
      }
    },

    /**
     * Save the data if CVS
     */
    fnSave: async function () {
      this.loading = true;
      this.errors = {};
      this.fnValidation();

      if (this.arrErr.length > 0) {
        return;
      }

      const formData = new FormData();
      formData.append("document_type", this.step2.document_type);
      formData.append("file", this.document);
      formData.append("project_id", this.projectId);
      formData.append("passport_number", this.step2.passport_number);
      formData.append("country_code", this.step2.country_code);
      formData.append("status", this.step2.status);

      try {
        const res = await chkRightWorkAustraliaService.updateUserInfo(
          this.userId,
          this.step1
        );

        const response = await chkRightWorkAustraliaService.addOrUpdate(
          this.projectId,
          formData
        );

        if (response.data.status_code == 200) {
          this.document = "";
          this.fetchRightWorkAustralia(this.projectId);
          this.fetchUserFromId(this.userId);

          if (this.loginUserObj.role_id == 9) {
            messageService.fnToastSuccess("Submit successfully!");
            this.$router.push({
              name: "checkSuccessfulSubmissionMsgCC",
              params: {
                productId: encodeDecodeService.cyEncrypt(this.productId),
              },
            });
          } else {
            messageService.fnToastSuccess("Saved successfully!");
          }
        } else {
          this.loading = false;
          return;
        }
        this.loading = false;
      } catch (error) {
        switch (error.response.status) {
          case 422:
            this.errors = error.response.data.errors;
            break;

          case 500:
            messageService.fnSweetAlertErrorToast(
              "Right to work in Australia",
              error.response.data.message
            );
            break;

          default:
            messageService.fnSweetAlertErrorToast(
              "Right to work in Australia",
              "Something wrong"
            );
            break;
        }

        console.log("Something wrong - fnCreate", error);

        this.loading = false;
      }
    },

    fnCallRefresh: function () {
      this.fetchUserFromId(this.userId);
      this.fetchRightWorkAustralia(this.projectId);
    },
    fnValidation() {
      this.arrErr = [];

      if (this.step2.doc_urls == "") {
        if (this.document.name == "" || this.document.name == null) {
          this.arrErr.push("Please upload copy of document");
        }
      }
      return this.arrErr;
    },
    /**
     * Check CC can update (submit)
     */
    can_cc_update() {
      let bol = false;
      let obj = this.getRightWorkAustralia;

      if (Object.keys(obj).length === 0) {
        bol = true;
      }

      if (obj.status < 2) {
        bol = true;
      }

      return bol;
    },

    /**
     * Check CVS can update (submit)
     */
    can_cvs_update() {
      let bol = false;
      let obj = this.getRightWorkAustralia;

      if (
        Object.keys(obj).length > 0 &&
        obj.status > 1 &&
        this.loginUserObj.role_id != 9
      ) {
        bol = true;
      }

      return bol;
    },
  },

  created() {
    this.loginUserObj = authService.getUserFromToken();
    this.fetchUserFromId(this.userId);
    this.fetchRightWorkAustralia(this.projectId);
  },

  watch: {
    getUserFromId(newVal) {
      Object.assign(this.step1, newVal);
    },
    getRightWorkAustralia(newVal) {
      Object.assign(this.step2, newVal);
      if (this.step2.status == 1) {
        this.fileRemoveDisabled = false;
      }
    },
  },
};
</script>
